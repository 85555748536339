.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center-v {
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.badge {
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
  color: white;
  width: fit-content;
}

.badge-success {
  background-color: #008000a0;
}

.badge-error {
  background-color: #ff000090;
}

.badge-warning {
  background-color: #f1c40f;
}

.badge-primary {
  background-color: var(--primary);
}

.badge-secondary {
  background-color: var(--secondary);
  color: var(--text-body);
}

.table-striped {
  tr:nth-child(odd) {
    background-color: #f0f0f0;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }
}

.table-not-striped {
  tr {
    background-color: #fff;
  }
}

.coming-soon {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #efefef;

  img {
    height: 30%;
    max-width: 80vw;
    margin-bottom: 4rem;
  }
  h3 {
    width: 750px;
    max-width: min(80vw);
  }

  @media (max-width: 499px) {
  }
}

.messaging-modal {
  height: 25rem;

  .contact-selects {
    border-right: 1px solid lightgray;

    .MuiFormControl-root {
      margin: 0 0 1rem 0;
    }
  }

  textarea {
    resize: none;
  }
}

.MuiPagination-ul {
  justify-content: center;
}

@media screen and (max-width: 767px) {
  thead.MuiTableHead-root {
    display: none;
  }
  tr.MuiTableRow-root {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.7rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  td.MuiTableCell-root {
    display: inline-block;
    width: 100%;
    padding: 0.5rem 0.7rem;
  }
}

.qr-viewfinder {
  height: 50%;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
  border: 2px solid red;
}

.hover:hover {
  background-color: #eee;
  transition: 0.3s;
}

.appear {
  animation: appear 0.4s ease-in-out;
}

@keyframes appear {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.fix-datepicker-scroll {
  position: fixed !important;
}

.mui-force-small {
  font-size: 16px !important;
  line-height: 1.3 !important;
}

.text-nowrap {
  white-space: nowrap;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.totem-blue {
  color: #06f3fe;
}

.totem-green {
  color: #0dfa88;
}

.cursor-none {
  cursor: none;
}

.hide-cursor-10s {
  animation: hideCursor 10s forwards;
  cursor: none;
}

@keyframes hideCursor {
  0% {
    cursor: default;
  }
  99% {
    cursor: default;
  }
  100% {
    cursor: none !important;
  }
}

.horizontal-scroll-table {
  table:first-child {
    box-shadow: 0 0 10px #e0e0e0;
  }

  div table {
    // margin-left: 1rem;
    box-shadow: unset !important;
    tr {
      td:first-of-type {
        padding-left: 1rem;
      }
    }
  }

  overflow-x: scroll;
  width: 100%;

  table {
    border-collapse: collapse;
  }

  thead {
    font-weight: bold;
    background-color: lightgray;
  }

  tr {
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    padding: 12px 16px 12px 16px;
    white-space: nowrap;
    // .table-wrap {
    //   height: 2.5rem;
    //   max-height: 2.5rem;
    //   overflow: hidden;
    //   text-overflow: ellipsis;

    // }
  }
}

.physical-evaluation-picture {
  width: 46vw;
  margin: 0 0.5% 0 0.5%;
  gap: 0.5rem;

  @media screen and (min-width: 767px) {
    width: 24vw;
  }
}

.pics-title {
  position: sticky;
  left: 0;
}

.react-calendar {
  border: none;
}
